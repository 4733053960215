﻿import {GA4EcommerceItem} from '../Models/GA4EcommerceItem'

const debug = true

const trackToDatalayer = (payload: object): void => {
	if (debug) {
		console.trace('datalayer push payload', payload)
	}

	if (Object.hasOwn(payload, 'ecommerce')) {
		window.dataLayer.push({ecommerce: null})
	}
	window.dataLayer.push(payload)
}

const trackToFacebookPixel = (event: string, payload: object): void => {
	const fbq = window.fbq || []

	if (debug) {
		console.trace('fbq push payload', payload)
	}

	fbq('track', event, payload)
}

const trackEcommerceOnLoad = () => {
	if (Object.hasOwn(window, 'domGaTrackingEvents') && window.domGaTrackingEvents.length > 0) {
		window.domGaTrackingEvents.forEach((domGaTrackingEvent: object) => {
			trackToDatalayer(domGaTrackingEvent)
		})
	}
	if (Object.hasOwn(window, 'domFbqTrackingEvents') && window.domFbqTrackingEvents.length > 0) {
		window.domFbqTrackingEvents.forEach((domFbqTrackingEvent: object) => {
			trackToFacebookPixel(domFbqTrackingEvent.event, domFbqTrackingEvent.payload)
		})
	}
}

const getOrderLineGaItem = (orderLine) =>{
	const gaItem = new GA4EcommerceItem()
	gaItem.item_id = orderLine.product.id
	gaItem.item_name = orderLine.product.name
	gaItem.price = orderLine.orderlinePriceInfo.totalPrice.raw
	gaItem.quantity = orderLine.quantity
	return gaItem
}

document.addEventListener('DOMContentLoaded', () => {
	trackEcommerceOnLoad()
})

export {
	trackToDatalayer,
	trackToFacebookPixel,
	GA4EcommerceItem,
	getOrderLineGaItem
}
