<script setup lang="ts">
import { onMounted, ref } from 'vue'

import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import { getTranslation } from '@/ts/utilities.ts'
import Spinner from "@/components/ui/decoration/Spinner.vue";
import ProductCard from './ProductCard.vue'

const props = defineProps({
	productId: {
		type: String,
	},
	variantId: {
		type: String,
	},
	take: {
		type: Number,
		default: 4
	},
	contain: {
		type: Boolean,
		default: true
	},
	hasBackground: {
		type: Boolean,
		default: false
	},
	type: {
		type: String,
		default: 'purchasedwith',
		validator: (value) => {
			return [
				'purchasedwith',
				'alsoseen',
				'recently',
				'popularproductingroup'
			].includes(<string>value)
		}
	},
	data: {
		type: Object,
		default: () => {}
	}
})

const productOptions = JSON.stringify({
	"options": {
		"priceAndCampaign": true,
		"media": true,
		"dimensions": true,
		"delivery": true,
		"cylindo": true,
		"variantOptionsSimple": true,
		"productDetailsSimple": true
	}
})

const isLoading = ref(false)
const productsError = ref()
const recommendations = ref('')

const getRelewiseData = async () => {
	let url = `/mobler-api/v2/products/recommendationsfeed?recommendationtype=${props.type}&PageSize=${props.take}`

	if (props.type == 'purchasedwith' || props.type == 'alsoseen' || props.type == 'recently') {
		url += `&currentproductid=${props.productId}&currentvariantid=${props.variantId}`
	} else if (props.type == 'popularproductingroup') {
		url += `&currentgroupid=${props.data?.groupId}`
	}

	await fetchData(url)
}

const getProductData = async () => {
	const products = props.data?.products;

	const productIds = products.map((product: any) => {
		if(product.variantId){
			return `${product.productId}_${product.variantId}`
		} else {
			return `${product.productId}`
		}
	}).join(',')
	let url = `/mobler-api/v2/products/productsFeed?productIds=${productIds}`

	await fetchData(url)
}

const fetchData = async (url: string) => {
	isLoading.value = true
	await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: productOptions
	})
	.then(response => {
		if (!response.ok) {
			throw new Error('Network response was not ok')
		}
		return response.json()
	})
	.then(data => {
		recommendations.value = data
	})
	.catch(error => {
		productsError.value = 'No product(s) found'
		console.log('Error:', error)
	})
	isLoading.value = false
}

const recommendationsHeadline = ref('')

const setHeadline = () => {
	if (props.type == 'purchasedwith') {
		recommendationsHeadline.value = getTranslation('Recommendations:PowerStep.Heading')
	} else if (props.type == 'alsoseen') {
		recommendationsHeadline.value = getTranslation('Recommendations:CustomersAlsoViewed.Heading')
	} else if (props.type == 'recently') {
		recommendationsHeadline.value = getTranslation('Recommendations:LatestVisitedProducts.Heading')
	}
}

const splideOptions = {
	type      : 'slide',
	rewind: true,
	perPage   : 1,
	perMove   : 1,
	gap: '1rem',
	pagination: false,
	arrows: true,
	drag: true,
	mediaQuery: 'min',
	breakpoints: {
		640: {
			perPage: 2,
		},
		1024: {
			perPage: 3,
		},
		1280: {
			perPage: 4,
		}
	}
}

onMounted(() => {
	if (!props.data?.isRelewise && props.data?.products) {
		getProductData()
	} else {
		getRelewiseData()
	}

	if (props.data?.title) {
		recommendationsHeadline.value = props.data?.title
	} else {
		setHeadline()
	}
})
</script>

<template>
	<!-- TODO: Look at programatically adding the slide functionality, determined on the amount of slides and the width of the slider
	https://splidejs.com/guides/overflow/
	This is because we struggle with always displaying a carousel on mobile (when we have more than 1 product), however on tablet and up
	it should consider how many we display "perPage" -->
	<Spinner v-if="isLoading" class="container min-h-[50vh]">
		{{ getTranslation('Products.Loading') }}
	</Spinner>
	<div v-else-if="productsError">
		{{ getTranslation('Products.Error') }} {{ productsError }}
	</div>
	<template v-else>
		<div
			v-if="recommendations?.products && recommendations?.totalProductsCount > 0"
			class="py-8"
			:class="props.hasBackground || props.data?.hasBackground ? 'bg-beige' : ''">
		<div :class="{'container': contain}" >
			<div class="flex flex-col gap-4 items-start md:flex-row md:items-center pb-6">
				<h3 v-if="recommendationsHeadline" id="carouselHeading" class="text-center md:text-left">{{ recommendationsHeadline }}</h3>
				<a v-if="props.data?.cta" :href="props.data?.cta" class="md:ml-auto px-6 py-2 rounded-full bg-black text-white hover:bg-black/90">
					{{ props.data?.ctaTitle }}
				</a>
			</div>
			<div
				v-if="props.take <= 4"
				class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
				>
				<ProductCard v-for="product in recommendations.products" :product="product" :key="product.key" />
			</div>
			<div
				v-if="props.take > 4"
				>
				<Splide
					:has-track="false"
					:options="splideOptions"
					aria-labelledby="carouselHeading">
					<SplideTrack>
						<SplideSlide v-for="product in recommendations.products" :key="product.key">
							<ProductCard :product="product" class="h-full" />
						</SplideSlide>
					</SplideTrack>

					<div class="splide__arrows">
						<button class="splide__arrow splide__arrow--prev -left-4"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
						<button class="splide__arrow splide__arrow--next -right-4"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
					</div>
				</Splide>
			</div>
		</div>
	</div>
	</template>
</template>
