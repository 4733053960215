<script setup lang="ts">
import ShowModal from '@/components/ShowModal.vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";


const props = defineProps({
	title: String,
	faqDummyArray: {
		type: Array,
		default: () => []
	},
	type: {
		type: String,
		default: 'list',
	}
})

const faqDummyArray = [
	{
		id: 1,
		icon: ['fal', 'truck'],
		question: 'Hvad er Lorem Ipsum?',
		answer: 'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper.'
	},
	{
		id: 2,
		icon: ['fak', 'emaerket'],
		question: 'Hvorfor bruger vi det?',
		answer: 'Det er et langt etableret faktum, at læseren vil blive distraheret af læsbart indhold på en side, når'
	}
]
</script>

<template>
<div class="border border-grey rounded"
	:class="{
		'px-6 py-2': props.type === 'trust',
		'p-6': props.type === 'list'
	}">
	<!-- TODO: Add Translation, FAQ.Title -->
	<h6 v-if="props.title" class="mb-4">{{ props.title }}</h6>
	<ul class="list-none flex flex-col gap-y-2 *:flex *:items-center *:gap-x-2 *:text-sm"
		:class="{
			'divide-y divide-grey': props.type === 'trust'
		}">
		<li
			v-for="faq in faqDummyArray"
			:key="faq.id"
			:class="{
				'py-2 *:w-full ': props.type === 'trust'
			}"
		>
			<ShowModal
				:modal-title="faq.question"
				:modal-content="faq.answer"
			>
				<font-awesome-icon
					v-if="props.type === 'trust' && faq.icon"
					:icon="faq.icon" />
				<span
					:class="props.type === 'trust' ? 'text-base font-bold' : ''">
					{{ faq.question }}
				</span>

				<font-awesome-icon
					v-if="props.type === 'trust'"
					class="ml-auto"
					:icon="['fal', 'chevron-right']" />
			</ShowModal>
		</li>
	</ul>
</div>
</template>

<style scoped>

</style>
