<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ShowModal from '@/components/ShowModal.vue'

const props = defineProps({
	options: {
		type: Array,
	},
	default: {
		type: Object,
	},
	infobox: {
		type: Object
	},
	label: {
		type: String,
		default: 'Label'
	}
})

const setVariant = (variantOption) => {
	emit('variantChange', variantOption)
}

const emit = defineEmits(['variantChange'])

</script>

<template>
	<div class="flex flex-col gap-2 min-w-60">
		<!-- Set a unique listbox ID, based on Vue component -->
		<div class="flex items-center justify-between">
			<div class="flex flex-col">
				<label class="font-bold text-sm">
					{{ props.label }}
				</label>
				<small>{{ props.options.find(option => option.selected).name }}</small>
			</div>
			<ShowModal
				v-if="infobox"
				:modal-title="props.infobox.heading"
				:modal-content="props.infobox.content"
				:button-options="{ action: 'button', theme: 'faded', size: 'sm' }"
			>
				<font-awesome-icon :icon="['fas', 'info-circle']" />
				Info
			</ShowModal>
		</div>
		<div class="flex items-center gap-2" >
			<div v-for="variantOption in props.options"
				 :key="variantOption.$id"
				 role="radio"
				 :aria-checked="variantOption.selected"
				 tabindex="-1"
				 class="w-[2.5rem] h-[2.5rem] rounded-full flex items-center justify-center hover:cursor-pointer hover:opacity-75"
				 :style="`background-color: ${variantOption.colorHex};`"
				 @click="setVariant(variantOption)"
				>
				<font-awesome-icon v-if="variantOption.selected" :icon="['fas', 'check']" class="text-white" />
			</div>
		</div>

	</div>
</template>
