import {getGuid} from '../utilities'
import {trackToDatalayer} from './tracking-helper'
import {AddToCartData, AddToCartProductData} from '@/interfaces/ProductViewInterface'

const endpoint: string = '/mobler-api/v2/ecom/cart/cartfeed'

const addToCart = (cartObj: AddToCartData) => {
	const isAddMulti = cartObj.products.length > 1
	let request = null

	if (isAddMulti) {
		request = getMultipleProductsRequest(cartObj)
	} else {
		request = getSingleProductRequest(cartObj)
	}

	if (request === null) {
		throw new Error('Request error')
	}
	return request.then(resp => resp.text()).then((result) => {

		// Send custom event
		const addedToCartEvent = new CustomEvent('productAddedToCart', {
			detail: {
				productId: cartObj.products[0].productId
			}
		})
		document.dispatchEvent(addedToCartEvent)

		//Track datalayer
		trackToDatalayer({
			event: 'add_to_cart',
			ecommerce: {
				items: [{
					item_id: cartObj.products[0].productId,
					item_name: cartObj.products[0].name,
					item_brand: cartObj.products[0].brandName,
					item_category: cartObj.products[0].category,
					price: cartObj.products[0].priceDouble,
					discount: cartObj.products[0].savingDouble,
					currency: 'DKK',
					quantity: cartObj.products[0].quantity,
					item_variant: cartObj.products[0].variantId
				}]
			}
		})

		return result
	}).catch((error) => {
		console.error(error)
	})
}


const getSingleProductRequest = (cartObj: AddToCartData) => {
	let endPointUrl = `${endpoint}?cartcmd=add&productId=${cartObj.products[0].productId}&quantity=${cartObj.products[0].quantity}`

	if (cartObj.cylindoOverride) {
		endPointUrl = `${endPointUrl}&EcomOrderLineFieldInput_CylindoAddOnFeatures=${cartObj.cylindoOverride.key} ${cartObj.cylindoOverride.feature}`
	}

	endPointUrl = `${endPointUrl}&Redirect=cartcmd.html`

	if (cartObj.products[0].variantId !== '' && cartObj.products[0].variantId !== null && cartObj.products[0].variantId !== undefined) {
		endPointUrl = `${endPointUrl}&variantid=${cartObj.products[0].variantId}`
	}

	return fetch(endPointUrl, {
		method: 'get'
	})
}


const getMultipleProductsRequest = (cartObj: AddToCartData) => {

	const optionalCylindoFeatures: string[] = []
	const formData = new FormData()
	const orderLineGUID = getGuid()

	formData.append('CartCmd', 'AddMulti')

	if (cartObj.cylindoOverride) {
		optionalCylindoFeatures.push(`${cartObj.cylindoOverride.key} ${cartObj.cylindoOverride.feature}`)
	}

	cartObj.products.forEach((productObject: AddToCartProductData) => {
		if (productObject.cylindoFeature) {
			optionalCylindoFeatures.push(productObject.cylindoFeature)
		}
	})

	cartObj.products.forEach((productObject: AddToCartProductData, index: number) => {
		const productLoopCounter = index + 1

		if (productObject.cylindoFeature) {
			if (index === 0) {
				formData.append(`EcomOrderLineFieldInput_CylindoAddOnFeatures${productLoopCounter}`, optionalCylindoFeatures.join('|'))
			}
			formData.append(`EcomOrderLineFieldInput_CylindoOrderlineGuid${productLoopCounter}`, orderLineGUID)
		}


		formData.append(`ProductLoopCounter${productLoopCounter}`, productLoopCounter.toString())
		formData.append(`ProductID${productLoopCounter}`, productObject.productId)
		formData.append(`VariantID${productLoopCounter}`, productObject.variantId)
		formData.append(`UnitID${productLoopCounter}`, '')
		formData.append(`Quantity${productLoopCounter}`, productObject.quantity.toString())
	})

	return fetch(endpoint, {
		method: 'post',
		body: formData
	})
}

const emptyCart = () => {
	const formData = new FormData()
	formData.append('CartCmd', 'emptycart')
	return fetch(endpoint, {
		method: 'POST',
		body: formData
	})
}

//OrderLines
const updateOrderLine = async (cartOptions: string, orderLineId: string, quantity: number) => {
	if (quantity < 1) {
		return deleteOrderLine(cartOptions, orderLineId)
	}
	const request = await fetch(`${endpoint}?CartCmd=updateorderlines&Redirect=false&QuantityOrderLine${orderLineId}=${quantity.toString()}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: cartOptions
	})
	return request.json()
}

const deleteOrderLine = async (cartOptions: string, orderLineId: string) => {
	const request = await fetch(`${endpoint}?CartCmd=delorderLine&Redirect=false&key=${orderLineId}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: cartOptions
	})
	return request.json()
}

const checkVoucher = async (voucherCode: string = '') => {
	const formData = new FormData()
	formData.append('EcomOrderVoucherCode', voucherCode)

	const request = await fetch('/mobler-api/v2/ecom/cart/validatevoucher', {
		method: 'POST',
		body: formData
	})
	return request.json()
}


export {addToCart, emptyCart, updateOrderLine, deleteOrderLine, checkVoucher}
