import * as ko from 'knockout';
import $ from 'jquery';
export default class MasterViewModel {

    public mobileMenuOpen: KnockoutObservable<boolean> = ko.observable(false as boolean);
    public cookieConsent: KnockoutObservable<boolean> = ko.observable(true as boolean);

    public checkForCookie = (name) => {

        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) {
                this.cookieConsent(true);
                return true;
            };
        }
        this.cookieConsent(false);
        return false;

    }

    public createCookie = () => {
        var date = new Date();
        date.setTime(date.getTime() + (100000000000));
        var expires = "; expires=" + date;
        document.cookie = "CookieConsent" + "=" + "true" + expires + "; path=/";
        this.cookieConsent(true);
    }

    public dismissCookie = () => {
        this.cookieConsent(true);
    }

    public hideWeply = () => {

        const observeWeplyIframe = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes.length > 0 && mutation.addedNodes[0].nodeName === "IFRAME" && mutation.addedNodes[0].src.includes("weply")) {
                   console.log('weply is added');
                    const weply = mutation.addedNodes[0];

                    // When weply is added, listen for changes to the document class
                    const observeMenuOpen = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                            if (mutation.attributeName === "class") {
                                const menuOpen = mutation.target.classList.contains("menu-open") || mutation.target.classList.contains("modal-open");
                                if (menuOpen) {
                                    weply.style.setProperty("display", "none", "important");
                                } else {
                                    weply.style.setProperty("display", "unset", "important");
                                }
                            }
                        });
                    });
                    observeMenuOpen.observe(document.documentElement, { attributes: true });

                    // When weply is added, listen for changes to the body
                    const observeModalOpen = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                            if (mutation.attributeName === "class") {
                                const modalOpen = mutation.target.classList.contains("menu-open") || mutation.target.classList.contains("modal-open")
                                if (modalOpen) {
                                    weply.style.setProperty("display", "none", "important");
                                } else {
                                    weply.style.setProperty("display", "unset", "important");
                                }
                            }
                        });
                    });
                    observeModalOpen.observe(document.body , { attributes: true });
                }
            });
        });

        observeWeplyIframe.observe(document.body, { childList: true, subtree: true });
    }

    constructor() {
        this.hideWeply();

        setTimeout(() => {

            this.checkForCookie("CookieConsent");

        }, 1000);
    }
}

ko.applyBindings(new MasterViewModel()); // This makes Knockout get to work


