import * as ko from 'knockout';
import $ from 'jquery';

export default class SetInitValue {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var observableToSet = value.observable;
        var valueToSet = value.value;

        observableToSet(valueToSet);
    }
}
ko.bindingHandlers['setInitValue'] = new SetInitValue();
