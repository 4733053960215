import * as ko from 'knockout';

export default class ViewModelBinding {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        if (!bindingContext)
            return;

        try {
            // TODO watch this because it may generate some runtime issues
            //let viewmodelClassRef = require(`../ViewModels/${valueAccessor()}`).default;

            async function importViewModel() {
                return await import(`../ViewModels/${valueAccessor()}.ts`);
            }
            importViewModel().then((module) => {
                let viewmodel = new module.default();
                ko.applyBindingsToDescendants(bindingContext.createChildContext(viewmodel), element);
            });
        }
        catch (error) {
            console.error(`Error caught inside ${valueAccessor()}, while parsing the 'viewModel' binding`);
            console.error(error);
            throw error;
        }
        return { controlsDescendantBindings: true };
    }
}

ko.bindingHandlers['viewModel'] = new ViewModelBinding();
ko.virtualElements.allowedBindings['viewModel'] = true;
