import * as ko from 'knockout';
import $ from 'jquery';

export default class UpdateObservableOnRadioChange {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var radioName = value.radioName;
        var observable = value.observable;

        $("input[name=" + radioName + "]").change(function () {
            var input = $(this);
            observable(input.data("price"));
        });
    }
}

ko.bindingHandlers['updateObservableOnRadioChange'] = new UpdateObservableOnRadioChange();
