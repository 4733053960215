import * as ko from 'knockout';
import $ from 'jquery';

export default class AltDeliveryStateHandler {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var observable = value.observable;
        $(element).on("change", function (e) {
            observable(!observable());
        });
    }
}

ko.bindingHandlers['altDeliveryStateHandler'] = new AltDeliveryStateHandler();
