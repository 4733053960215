<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {AddToCartData, CarouselData, ProductViewData, RelewiseData} from '@/interfaces/ProductViewInterface'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {getTranslation, getSettings, goTo, readMore} from '@/ts/utilities.ts'
import RenderAction from '@/components/helpers/RenderAction.vue'
import SelectElement from '@/components/ui/form/SelectElement.vue'
import Spinner from '@/components/ui/decoration/Spinner.vue'
import PriceElement from '@/components/PriceElement.vue'
import Cylindo from '@/ts/CustomBindings/Cylindo.ts'
import { addToCart } from '@/ts/Helpers/cart-helper.ts'
import ShowModal from '@/components/ShowModal.vue'
import ProductCarousel from '@/components/ProductCarousel.vue'
import PowerStep from '@/components/PowerStep.vue'
import VariantSelector from '@/components/VariantSelector.vue'
import VariantModal from '@/components/VariantModal.vue'
import RadioGroup from '@/components/ui/form/RadioGroup.vue'
import Recommendations from '@/components/Recommendations.vue'
import PaymentMethodLogos from '@/components/blocks/PaymentMethodLogos.vue'
import {GA4EcommerceItem, trackToDatalayer} from '@/ts/Helpers/tracking-helper.ts'

interface Props {
	productId: string,
	variantId: string,
	relewiseData: object,
	areaId: number
}

const props = defineProps<Props>()

let product = ref(<ProductViewData>{})
const settings = getSettings()
let usps = ref({})

let productBoxes = ref([])
let productBullets = ref([])

const areaSelectedVariantColorGroupIds = ref([])

const relewiseData: RelewiseData = {
	isAnonymous: props.relewiseData.userIsAnonymous,
	showRecommendations: props.relewiseData.showRecommendations
}

let translations = ref({})
translations.value = {
	ProductNumber: getTranslation('ProductProductNumber'),
	Shortcuts: getTranslation('ShortCuts'),
	ProductDesignYourselfTitle: getTranslation('Product.DesignYourself.Title'),
	ProductInspirationBrochureTitle: getTranslation('Product.InspirationBrochure.Title'),
	ProductProductDescriptionAboutLinkText: getTranslation('Product.ProductDescription.AboutLinkText'),
	ProductProductDescriptionTitle: getTranslation('Product.ProductDescription.Title'),
	ProductProductDescriptionReadMoreTitle: getTranslation('Product.ProductDescription.ReadMore.Title'),
	ProductSpecificationsTitle: getTranslation('Product.Specifications.Title'),
	ProductSpecificationsReadMoreTitle: getTranslation('Product.Specifications.ReadMore.Title'),
	ProductViewInStore: getTranslation('Se produktet i en af vores butikker'),
	ProductAddToCart: getTranslation('ProductAddToCart'),
	HeaderNavFindShop: getTranslation('HeaderNav.FindShop'),
	ProductFinancingTitle: getTranslation('kr./måned i 10 måneder')
}

let showVariantModal = ref(false)
let showVariantSelector = ref(false)
let showMoreOptionsSelector = ref(false)
let selectedVariantId = ref('')
let selectedCylindoFeature = ref('')
let variantGroupData = ref({})

const selectedOptionalProducts: object = ref({})
const selectedVariantData: object = ref({})
const variantChange = (variantId, variant) => {
	if (variantId && variant) {
		setVariantId(variantId)
		showVariantModal.value = false
		showVariantSelector.value = false

		if (!product.value.cylindo.isUsingCylindoAssets) return
		setCylindoFeatures(variant)
	} else{
		console.error('MISSING VariantId / Variant')
	}
}

const variantInlineChange = (variant, group) =>{
	const groupsIndex = product.value.variantOptionsAdvanced.variantGroups.findIndex(g => g.id === group.id)
	const variantIdParts = product.value.variantId.split('.')
	variantIdParts[groupsIndex] = variant.id
	setVariantId(variantIdParts.join('.'))

	if (!product.value.cylindo.isUsingCylindoAssets) return
	setCylindoFeatures(variant)
}

const removeOptionalProduct = async (moreOptions: object) =>{
	const removedProduct = {...selectedOptionalProducts.value[moreOptions.id]}
	delete selectedOptionalProducts.value[moreOptions.id]
	showMoreOptionsSelector.value = false

	let productId = props.productId
	if(selectedVariantId.value){
		productId = `${props.productId}_${selectedVariantId.value}`
	}

	await getProductData(productId)

	if (!removedProduct?.product?.isCylindoProduct) return

	//remove value of feature so the key can be removed
	removedProduct.product.features = removedProduct.product.features.split(' ')[0]

	setCylindoFeatures(removedProduct.product)
}

const addOptionalProduct = async (product: object, moreOptions: object) =>{
	selectedOptionalProducts.value[moreOptions.id] = {product:product, group:moreOptions}
	showMoreOptionsSelector.value = false

	let productId = props.productId
	if(selectedVariantId.value){
		productId = `${props.productId}_${selectedVariantId.value}`
	}
	await getProductData(productId)

	if (!product.isCylindoProduct) return
	setCylindoFeatures(product)
}


const setVariantId = (variantId: object) => {
	selectedVariantId.value = variantId
	getProductData(`${props.productId}_${selectedVariantId.value}`)
}

const carouselData: CarouselData = ref({})

const setCarouselData = (product: ProductData) => {
	carouselData.value = {
		name: product.value.name,
		media: {
			images: product?.value.media?.images,
			videos: product?.value.media?.videos,
			productImage: product?.value.media?.productImage,
			// TODO: Refactor the next line to have correct data
			cylindoImages: [...Cylindo.getDetailsGrid(product?.value.cylindo.defaultFeatures.split(' '), product?.value.cylindo.id)],
			cylindoDimensionShot: product?.value.media?.cylindoDimensionShot,
		},
		cylindo:product.value.cylindo,
		productSplash: product.value.priceAndCampaign?.productSplash
		// onTopSplatterText: props.productData.onTopSplatterText,
		// onTopSplatterType: props.productData.onTopSplatterType
	}
}

const setCylindoFeatures = (variant: object) => {
	if (variant.materialType) {
		Object.assign(Cylindo.selectedCylindoFeatures, {MATERIAL: variant.materialType})
		selectedCylindoFeature.value = `MATERIAL ${variant.materialType}`
	}

	if (variant.cylindoVariantKeyValue) {
		const splitCylindoVariantKeyValue = variant.cylindoVariantKeyValue.split(' ')
		Object.assign(Cylindo.selectedCylindoFeatures, {[splitCylindoVariantKeyValue[0].toUpperCase()]: splitCylindoVariantKeyValue[1]})
		selectedCylindoFeature.value = selectedCylindoFeature.value + ' ' + variant.cylindoVariantKeyValue
	}

	if (variant.features) {
		const splitCylindoVariantKeyValue = variant.features.split(' ')
		if(splitCylindoVariantKeyValue[0] && splitCylindoVariantKeyValue[1]){
			Object.assign(Cylindo.selectedCylindoFeatures, {[splitCylindoVariantKeyValue[0].toUpperCase()]: splitCylindoVariantKeyValue[1]})
		} else if(splitCylindoVariantKeyValue[0] && !splitCylindoVariantKeyValue[1]){
			delete Cylindo.selectedCylindoFeatures[splitCylindoVariantKeyValue[0].toUpperCase()]
		}

		selectedCylindoFeature.value = selectedCylindoFeature.value + ' ' + variant.cylindoVariantKeyValue
	}

	if (Cylindo.shouldLoad() && selectedCylindoFeature.value) {
		Cylindo.setFeatures()
	}
}

const getSelectedMoreProductLabel = (moreOptionGroup) =>{
	const selectedMoreOption = moreOptionGroup.moreOptionsDetails.filter(o => o.isSelected)
	if(selectedMoreOption.length){
		return `${selectedMoreOption[0].name} (+${selectedMoreOption[0].priceAndCampaign.price.formatted})`
	}
	return `${getTranslation('Vælg')} ${moreOptionGroup.name.toLowerCase()}`
}

const hasSpecifications = () => {
	return product?.value.specifications
}

const setSpecifications = (specifications: object) => {
	if (!hasSpecifications()) return

	return Object.entries(specifications)
		.map(([key, value]) => {
			return `
				<dt class="font-bold">${key}</dt>
				<dd>${value}</dd>
			`
		})
		.join('')
}

const productMoreIdModal = ref('')

const hasVariants = () => {
	return product?.value.variantOptionsAdvanced?.variantGroups?.length > 0
}

const isColorGroup = (variantGroup) =>{
	return areaSelectedVariantColorGroupIds.value.includes(variantGroup.id)
}

const structureVariantInfoForModal = (variantGroup) => {
	return {
		heading: variantGroup.informationTitle ? variantGroup.informationTitle : variantGroup.name,
		content: variantGroup.informationDescription ? variantGroup.informationDescription : variantGroup.variantDescription
	}
}

const structureMoreProductsInfoForModal = (moreProductsGroup) =>{
	return {
		heading: moreProductsGroup.name,
		content: getTranslation('TilvalgInfoboks')
	}
}

// Prepare for variant loading
const isLoading = ref(false)

//Add to cart
let showPowerStep = ref(false)
const addProductToCart = () => {
	const cartObj: AddToCartData = {
		products: [
			...[{
				name: product.value.name,
				productId: product.value.id,
				variantId: selectedVariantId.value,
				quantity: 1,
				cylindoFeature: selectedCylindoFeature.value
			}]
		]
	}
	Object.values(selectedOptionalProducts.value).forEach((optionalProduct) =>{
		const splitId = optionalProduct.product.id.split('_')
		cartObj.products.push({
			name: optionalProduct.product.name,
			productId: splitId[0],
			variantId: splitId.length > 1 ? splitId[1] : '',
			quantity: 1,
			cylindoFeature: selectedCylindoFeature.value
		})
	})

	showPowerStep.value = true

	addToCart(cartObj).then(() => {
		showPowerStep.value = true
	})
}

const productError = ref(null)

const getArea = async () =>{
	const areaRequest = await fetch(`/dwapi/content/areas/${props.areaId}`)
	const areaResponds = await areaRequest.json()
	const colorGroupItem = areaResponds.Item.Fields.find(itm => itm.SystemName === 'CylindoVariantColorGroups')
	areaSelectedVariantColorGroupIds.value = colorGroupItem.Value.SelectedValues
}

const variantModalData = ref(null)
const loadVariantModal = async (variantGroup) =>{
	variantModalData.value = await getVariantGroupData(variantGroup)
	showVariantModal.value = true
}

const variantSelectorData = ref(null)
const moreOptionsSelectorData = ref(null)
const loadVariantSelector = async (variantGroup) =>{
	variantSelectorData.value = await getVariantGroupData(variantGroup)
	if(variantSelectorData.value.variantGroupDetails.videoLink === '' && variantGroup.videoLink !== ''){
		variantSelectorData.value.variantGroupDetails.videoLink = variantGroup.videoLink

	}
	showVariantSelector.value = true
}

const loadMoreOptionsSelector = async (moreOptionsGroup, productMoreId = '') =>{
	moreOptionsSelectorData.value = moreOptionsGroup
	productMoreIdModal.value = productMoreId
	showMoreOptionsSelector.value = true
}

const getVariantGroupData = async (variantGroup) => {
	const request = await fetch(`/mobler-api/v2/products/variantgroupfeed?variantgroupid=${variantGroup.id}&productid=${product.value.id}&variantid=${product.value.variantId}&cylindoproductid=${product.value.cylindo.id}&languageid=${product.value.languageId}&displaytype=${variantGroup.displayType}`)
	return await request.json()
}

const productOptions = {
	options: {
		priceAndCampaign: true,
		productDetailsAdvanced: true,
		media: true,
		dimensions: true,
		delivery: true,
		cylindo: true,
		specifications: true,
		variantOptionsAdvanced: true,
		moreOptions: true,
		moreOptionsDetails: true,
		usps: true
	}
}

async function getProductData (productIds = '') {
	isLoading.value = true
	if(productIds === ''){
		productIds = `${props.productId}_${props.variantId}`
		if(props.variantId !== 0){
			productIds = `${props.productId}_${props.variantId}`
		} else {
			productIds = `${props.productId}`
		}
	}

	if(Object.keys(selectedOptionalProducts.value).length){
		const moreOptionsProducts = []
		Object.values(selectedOptionalProducts.value).forEach((optionalProduct) =>{
			moreOptionsProducts.push({
				groupId: optionalProduct.group.id,
				groupName: optionalProduct.group.name,
				selectedProductId: optionalProduct.product.id
			})
		})
		productOptions.moreOptionsProducts = moreOptionsProducts
	} else{
		delete productOptions.moreOptionsProducts
	}


	await fetch(`/mobler-api/v2/products/productsFeed?productIds=${productIds}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(productOptions)
	})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.then(data => {
			let products = data.products

			if (products.length > 0) {
				// we only want the first Products object in the Products array of the returned data:
				product.value = products[0]
				selectedCylindoFeature.value = product.value.cylindo.defaultFeatures
				selectedVariantId.value = product.value.variantId

				trackProduct()

				// Create carouselData
				setCarouselData(product)

				// Set Product USPs
				productBoxes.value = products[0]?.usps?.productBoxes
				productBullets.value = products[0]?.usps?.productBullets
			} else {
				productError.value = 'No product found'
			}
		})

		.catch(error => {
			productError.value = error
			console.error('There has been a problem with your fetch operation:', error)
		})

	isLoading.value = false
}

const trackProduct = () =>{
	let ga4EcomItem: GA4EcommerceItem = new GA4EcommerceItem()
	ga4EcomItem.item_id = product.value?.id
	ga4EcomItem.item_name = product.value?.name
	ga4EcomItem.item_brand = product.value?.brand
	ga4EcomItem.item_image = product.value?.media?.images?.find(i => i.isPrimary)?.url ?? product.value?.media?.productImage
	ga4EcomItem.price = product.value?.priceAndCampaign?.price?.raw
	ga4EcomItem.currency = 'DKK'
	ga4EcomItem.item_variant = product.value?.variantId
	trackToDatalayer({
		event: 'view_item',
		ecommerce: {
			items: [ga4EcomItem]
		}
	})
}

onMounted(async () => {
	await getArea()
	await getProductData()
	const defaultCylindoFeatures = product.value?.cylindo?.defaultFeatures.split('|')
	const otherCylindoFeatures = product.value?.cylindo?.feature.split('|')
	Object.assign(Cylindo.selectedCylindoFeatures, Cylindo.convertCylindoFeatureArrayToObject([...defaultCylindoFeatures, ...otherCylindoFeatures]))
	Cylindo.setFeatures()
	trackProduct()
})

</script>

<template>
	<Spinner v-if="isLoading" class="container min-h-[50vh]">
		{{ getTranslation('Product.Loading') }}
	</Spinner>
	<div v-else-if="productError">
		{{ getTranslation('Product.Error') }} {{ productError }}
	</div>
	<div v-else>
		<div class="container grid xl:grid-cols-4 items-baseline gap-y-2 xl:gap-y-0 xl:gap-x-8 mb-4 xl:mb-2">
			<h1 class="text-balance xl:col-span-3">{{ product?.name }}</h1>
			<p class="color-subtle text-sm xl:text-right">
				{{ translations.ProductNumber }}
				<span>{{ product?.number }}</span>
			</p>
		</div>

		<div class="product grid xl:grid-cols-4 xl:auto-rows-max gap-y-4 gap-x-8 mb-8 container overflow-hidden">

			<ProductCarousel
				v-if="product?.media?.images || product?.media?.videos || product?.cylindo?.isUsingCylindoAssets"
				class="xl:col-span-3"
				:class="carouselData?.cylindo?.isUsingCylindoAssets ? 'js-productcarousel-is-using-cyldino-assets' : ''"
				:carousel-data="carouselData"
				:cylindo-features="carouselData?.cylindo?.isUsingCylindoAssets ? selectedCylindoFeature : ''"
			>
			</ProductCarousel>

			<div
				v-if="hasSpecifications() || product?.productDetailsAdvanced?.providerLink || product?.productDetailsAdvanced?.catalogLink"
				class="shortcuts flex flex-col md:flex-row md:items-center z-0 gap-2 lg:gap-4 xl:col-span-3">
				<div class="hidden md:flex shortcuts__header">
					<small><strong>{{ translations.Shortcuts }}</strong></small>
				</div>
				<ul class="list-none flex flex-wrap gap-4 *:items-center *:gap-2">
					<li v-if="product?.productDetailsAdvanced?.longDescription"
						class="block xl:hidden">
						<RenderAction
							action="button"
							theme="faded"
							size="sm"
							@click="goTo($refs.longDescription)">
							{{ translations.ProductProductDescriptionTitle }}
						</RenderAction>
					</li>
					<li v-if="hasSpecifications()">
						<RenderAction
							action="button"
							theme="faded"
							size="sm"
							@click="goTo($refs.specifications)">
							<font-awesome-icon :icon="['fal', 'clipboard']"/>
							{{ translations.ProductSpecificationsTitle }}
						</RenderAction>
					</li>
					<li v-if="product?.productDetailsAdvanced?.providerLink" class="hidden xl:flex">
						<RenderAction
							:data="product.productDetailsAdvanced.providerLink"
							action="link"
							target="external"
							theme="faded"
							size="sm">
							<font-awesome-icon :icon="['fal', 'pen']"/>
							{{ translations.ProductDesignYourselfTitle }}
						</RenderAction>
					</li>
					<li v-if="product?.productDetailsAdvanced?.catalogLink" class="hidden xl:flex">
						<RenderAction
							:data="product.productDetailsAdvanced.catalogLink"
							action="link"
							target="external"
							theme="faded"
							size="sm">
							<font-awesome-icon :icon="['fal', 'book-open']"/>
							{{ translations.ProductInspirationBrochureTitle }}
						</RenderAction>
					</li>
				</ul>
			</div>

			<!-- Descriptions -->
			<!-- TODO: Make component of descriptions, updates on variant change -->
			<div class="short-description xl:col-span-3">
				<div v-html="product?.shortDescription"></div>
				<ul class="list-none flex gap-x-2 text-base">
					<li v-if="product?.dimensions?.depth > 0">D/L: {{ product?.dimensions.depth }}</li>
					<li v-if="product?.dimensions?.height > 0">H: {{ product?.dimensions.height }}</li>
					<li v-if="product?.dimensions?.width > 0">B: {{ product?.dimensions.width }}</li>
				</ul>
			</div>

			<div v-if="product?.productDetailsAdvanced?.longDescription"
				 class="flex flex-col relative xl:col-span-3"
				 ref="longDescription">
				<h2 class="text-pretty mb-4">{{ translations.ProductProductDescriptionTitle }}</h2>

				<div class="product-description--inner read-more-container">
					<div class="prose-sm lg:prose lg:max-w-none" v-html="product?.productDetailsAdvanced?.longDescription"></div>
				</div>
				<div class="read-more-fade">
					<RenderAction
						action="button"
						theme="outline"
						size="sm"
						@click="readMore($refs.longDescription)"
					>
						{{ translations.ProductProductDescriptionReadMoreTitle }}
						<font-awesome-icon :icon="['fas', 'chevron-down']"/>
					</RenderAction>
				</div>
			</div>
			<ul class="list-none flex flex-wrap gap-4 *:items-center *:gap-2">
				<li v-if="product?.productDetailsAdvanced?.providerLink" class="flex xl:hidden">
					<RenderAction
						:data="product.productDetailsAdvanced.providerLink"
						action="link"
						target="external"
						theme="faded"
						size="sm">
						<font-awesome-icon :icon="['fal', 'pen']"/>
						{{ translations.ProductDesignYourselfTitle }}
					</RenderAction>
				</li>
				<li v-if="product?.productDetailsAdvanced?.catalogLink" class="flex xl:hidden">
					<RenderAction
						:data="product.productDetailsAdvanced.catalogLink"
						action="link"
						target="external"
						theme="faded"
						size="sm">
						<font-awesome-icon :icon="['fal', 'book-open']"/>
						{{ translations.ProductInspirationBrochureTitle }}
					</RenderAction>
				</li>
			</ul>

			<!-- Specifications -->
			<!-- TODO: Make component, updates on variant change -->
			<div v-if="hasSpecifications()"
				 class="relative xl:col-span-3"
				 ref="specifications">
				<h2 class="text-pretty mb-4">{{ translations.ProductSpecificationsTitle }}</h2>

				<dl class="flex flex-wrap w-full read-more-container *:py-4 *:w-1/2 *:border-t *:border-grey"
					v-html="setSpecifications(product?.specifications)">
				</dl>

				<div class="read-more-fade">
					<RenderAction
						action="button"
						theme="outline"
						size="sm"
						@click="readMore($refs.specifications)"
					>
						{{ translations.ProductSpecificationsReadMoreTitle }}
						<font-awesome-icon :icon="['fas', 'chevron-down']"/>
					</RenderAction>
				</div>
			</div>

			<div class="row-start-3 xl:row-start-1 xl:row-span-5 xl:col-start-4 ">
				<div class="border-grey border rounded mb-4 flex flex-col">
					<div class="p-4 border-b border-grey">
						<PriceElement
							v-if="product?.priceAndCampaign"
							theme="page"
							:price-data="product?.priceAndCampaign"
						/>
						<ul class="mt-2 list-none"
							v-if="product?.priceAndCampaign?.moreOptionsText">
							<li class="text-xs text-grey-dark leading-2" v-if="product?.priceAndCampaign?.moreOptionsText">{{ product?.priceAndCampaign?.moreOptionsText }}</li>
						</ul>
					</div>

					<!-- Filters -->
					<!-- Filter Group -->
					<div v-if="hasVariants()"
						 class="flex flex-col *:border-b *:border-grey *:p-4">

						<div v-for="variantGroup in product?.variantOptionsAdvanced?.variantGroups">
							<!-- If is a color group -->
							<template v-if="isColorGroup(variantGroup)">
								<!-- If color group should display as modal -->
								<template v-if="variantGroup?.variantOptionDetails?.length === 0">
									<SelectElement
										:label="variantGroup.name"
										:infobox="structureVariantInfoForModal(variantGroup)"
										type="modal"
										@click="loadVariantModal(variantGroup)"
									>
										{{ variantGroup.selectedName }}
									</SelectElement>

									<VariantModal
										v-if="variantModalData"
										:show="showVariantModal"
										:product-data="product"
										:variant-group-data="variantModalData"
										:color-group-ids="areaSelectedVariantColorGroupIds"
										@close="showVariantModal = false"
										@variantChange="variantChange"/>
								</template>

								<!-- If color group should display inline -->
								<template v-else>
									<RadioGroup
										:label="variantGroup.name"
										:infobox="structureVariantInfoForModal(variantGroup)"
										:options="variantGroup.variantOptionDetails"
										@variantChange="variantInlineChange($event, variantGroup)"
									/>
								</template>

							</template>
							<!-- If is other than color group -->
							<template v-else>
								<SelectElement
									:key="variantGroup.$id"
									:options="variantGroup.variantOptionDetails"
									:default="variantGroup.variantOptionDetails.filter(option => option.selected)"
									:label="variantGroup.name"
									type="modal"
									:infobox="structureVariantInfoForModal(variantGroup)"
									@click="loadVariantSelector(variantGroup)">
									{{ variantGroup.selectedName }}
								</SelectElement>
							</template>
						</div>
					</div>

					<div v-if="product?.moreOptions"
						 class="flex flex-col *:border-b *:border-grey *:p-4">
						<div class="flex items-center justify-between gap-4">
							<p class="text-xl">{{ getTranslation('Tilvalg') }}</p>
							<ShowModal
								:modal-title="getTranslation('Tilvalg')"
								:modal-content="getTranslation('TilvalgInfoboks')"
								:button-options="{ action: 'button', theme: 'faded', size: 'sm' }"
							>
								<font-awesome-icon :icon="['fas', 'info-circle']" />
								{{ getTranslation('Info') }}
							</ShowModal>
						</div>
						<SelectElement
							v-for="moreOption in product?.moreOptions"
							:key="moreOption.$id"
							:options="moreOption.moreOptionsDetails"
							:default="moreOption.moreOptionsDetails.filter(o => o.isSelected)"
							:label="moreOption.name"
							@click="loadMoreOptionsSelector(moreOption)"
							type="modal"
							>
							{{ getSelectedMoreProductLabel(moreOption) }}
						</SelectElement>
					</div>

					<VariantSelector
						v-if="moreOptionsSelectorData && product?.moreOptions"
						type="moreOptions"
						:show="showMoreOptionsSelector"
						:product-data="product"
						:moreOptions-group-data="moreOptionsSelectorData"
						:product-more-id="productMoreIdModal"
						@close="showMoreOptionsSelector = false"
						@addOptionalProduct="addOptionalProduct" @removeOptionalProduct="removeOptionalProduct" variant-group-data=""/>

					<VariantSelector
						v-if="variantSelectorData"
						type="variants"
						:show="showVariantSelector"
						:product-data="product"
						:variant-group-data="variantSelectorData"
						@close="showVariantSelector = false"
						@variantChange="variantChange" more-options-group-data=""/>

					<!-- Actions -->
					<div class="flex flex-col gap-4 p-4">
						<RenderAction
							v-if="product?.seeProductInStore"
							:data="product.seeProductInStore"
							action="link"
							theme="link"
							size="lg"
							@click=""
						>
							{{ translations.ProductViewInStore }}
						</RenderAction>

						<!-- If For Sale -->
						<RenderAction
							v-if="product?.priceAndCampaign?.forSale"
							action="button"
							theme="primary"
							size="lg"
							@click="addProductToCart"
						>
							<font-awesome-icon :icon="['fas', 'cart-shopping']"/>
							{{ translations.ProductAddToCart }}
						</RenderAction>

						<RenderAction
							v-if="settings?.findDealerLink && product?.priceAndCampaign?.forSale"
							:data="settings?.findDealerLink"
							action="link"
							theme="outline"
							size="lg"
						>
							<font-awesome-icon :icon="['fas', 'shop']"/>
							{{ translations.HeaderNavFindShop }}
						</RenderAction>

						<div v-if="product?.priceAndCampaign?.showFinancing && product?.priceAndCampaign?.pricePerMonthFinancing?.formatted != ''"
							 class="flex justify-center flex-wrap gap-2 rounded p-4 border-grey border">
							<PaymentMethodLogos :financing="true" />
							<div class="flex gap-1">
								<span>{{ product?.priceAndCampaign?.pricePerMonthFinancing?.formatted }}</span>
								<span>{{ translations.ProductFinancingTitle }}</span>
							</div>
						</div>
					</div>
				</div>

				<ul class="list-none flex flex-col gap-y-2 *:flex *:items-center *:gap-x-2">
					<li v-if="product?.delivery?.quickDelivery || product?.delivery?.displayAverageDeliveryTime && !product?.delivery?.hideDelivery"
						:style="{ 'color': product.delivery.deliveryColor }">
						<ShowModal
							:modal-title="product?.delivery?.deliveryTime"
							:modal-content="product?.delivery?.deliveryDescription">
							<font-awesome-icon :icon="['fal', 'truck-fast']" class="min-w-[2rem] text-lg" />
							<small>{{ product.delivery.deliveryTime }}</small>
						</ShowModal>
					</li>

					<template v-if="usps">
						<li
							v-for="(usp, index) in productBullets"
							:key="index">
							<ShowModal
								v-if="usp.showContentInModal"
								:modal-title="usp.header"
								:modal-content="usp.contentText">
								<font-awesome-icon :icon="['fal', usp.icon]"  class="min-w-[2rem] text-lg" />
								<small>{{ usp.header }}</small>
							</ShowModal>

							<RenderAction
								v-else-if="usp.link"
								theme="link"
								size="none"
								:link="usp.link">
								<font-awesome-icon v-if="usp.icon" :icon="['fal', usp.icon]"  class="min-w-[2rem] text-lg" />
								<small>{{ usp.header }}</small>
							</RenderAction>

							<template v-else>
								<font-awesome-icon v-if="usp.icon" :icon="['fal', usp.icon]" class="min-w-[2rem] text-lg" />
								<small>{{ usp.header }}</small>
							</template>
						</li>
					</template>
				</ul>

			</div>
		</div>
	</div>

	<!-- Video, just for testing the VideoBlock component, not to be used here -->
<!--	<div v-if="productData.media.videos"-->
<!--		class="py-8">-->
<!--		<div v-for="video in productData.media.videos">-->
<!--			<VideoBlock-->
<!--				:video="video"-->
<!--			/>-->
<!--		</div>-->
<!--	</div>-->

	<Recommendations
		v-if="relewiseData?.showRecommendations && product?.id"
		type="alsoseen"
		:product-id="product?.id"
		:variant-id="product?.variantId"
		:has-background="true"
		:take="8"
	/>

	<div class="py-8" v-if="productBoxes">
		<div class="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
			<div v-for="(box, index) in productBoxes" :key="index" class="rounded border border-grey-light overflow-hidden">
				<div v-if="box.image"
					 class="bg-white-warm">
					<img
						:src="'/Admin/Public/GetImage.ashx?Image=' + box.image + '&Width=600&height=375&Format=webP&Quality=90&Crop=5&resolution=50'"
						:alt="box.name"
						width="600"
						height="375"
						class="object-contain aspect-[16/10]"
						loading="lazy"
					>
				</div>
				<div class="flex flex-col gap-y-6 p-8 h-full bg-beige-light">
					<div class="flex items-center gap-2">
						<font-awesome-icon
							v-if="box.icon"
							:icon="['fal', box.icon]"
							class="text-2xl"
						/>
						<h3 class="text-pretty">{{ box.header }}</h3>
					</div>
					<template v-if="box.type === 'BULLETS' && box.bulletUsps">
						<ul class="list-none flex flex-col gap-y-4">
							<li class="flex gap-x-2 items-baseline" v-for="(bullet, index) in box.bulletUsps" :key="index">
								<font-awesome-icon
									v-if="bullet.icon"
								   :icon="['fal', bullet.icon]"
								/>
								<p class="flex flex-col">
									<strong>{{ bullet.header }}</strong>
									{{ bullet.teaser }}
								</p>
							</li>
						</ul>
					</template>
					<p v-else>{{ box.teaser }}</p>
					<!-- TODO: Add external/internal logic based on backend, missing functionality for now -->
					<RenderAction
						v-if="box.buttonText && box.buttonLink"
						action="link"
						theme="link"
						target="external"
						size="none"
						:data="box.buttonLink"
						>
						{{ box.buttonText }}
					</RenderAction>
				</div>
			</div>
		</div>
	</div>

	<Recommendations
		v-if="!relewiseData?.userIsAnonymous && relewiseData?.showRecommendations && product?.id"
		type="recently"
		:product-id="product?.id"
		:variant-id="product?.variantId"
		:has-background="true"
		:take="8"
	/>
	<PowerStep
		:show="showPowerStep"
		:product="product"
		@close="showPowerStep = false"
	/>
</template>
