﻿
export class GA4EcommerceItem {

    public item_id: string;
    public item_name: string;
    public affiliation: string;
    public coupon: string;
    public currency: string;
    public discount: number;
    public index: number;

    public item_image: string;
    public item_brand: string;
    public item_category: string;
    public item_category2: string;
    public item_category3: string;
    public item_category4: string;
    public item_category5: string;
    public item_list_id: string;
    public item_list_name: string;
    public item_variant: string;
    public location_id: string;
    public price: number;
    public quantity: number;

    constructor() {
        this.item_id = null;
        this.item_name = null;
        this.affiliation = null;
        this.coupon = null;
        this.currency = null;
        this.discount = null;
        this.index = null;
        this.item_image = null;
        this.item_brand = null;
        this.item_category = null;
        this.item_category2 = null;
        this.item_category3 = null;
        this.item_category4 = null;
        this.item_category5 = null;
        this.item_list_id = null;
        this.item_list_name = null;
        this.item_variant = null;
        this.location_id = null;
        this.price = null;
        this.quantity = null;
    }

}
