import { library, dom } from '@fortawesome/fontawesome-svg-core'
// Light icons used in general
import {
	faArrowLeft, faArrowDown, faArrowUp, faArrowRight, faArrowUpRightFromSquare, faTrash, faPen, faPlusCircle, faMinusCircle, faPlus, faMinus, faAngleRight, faChevronDown, faChevronLeft, faChevronRight, faSpinner, faTimes, faTimesCircle, faCircleXmark, faPlayCircle, faInfoCircle, faCircleNotch, faCheck, faCheckCircle, faMagnifyingGlass, faSearch, faClipboard, faBookOpen, faCalendar, faTruck, faPiggyBank, faTruckFast, faShop, faBars, faXmark, faPlay,
	faCartShopping, faPhone, faTag, faLock, faTags
} from '@awesome.me/kit-79798a8675/icons/classic/light'
// Solid icons used for buttons
import { faArrowLeft as fasFaArrowLeft, faArrowRight as fasArrowRight, faArrowDown as fasFaArrowDown, faChevronDown as fasFaChevronDown, faCartShopping as fasFaCartShopping, faInfoCircle as fasFaInfoCircle, faCircleXmark as fasFaCircleXmark, faShop as fasFaShop, faCheck as fasFaCheck, faBars as fasBars, faTag as fasTag, faSliders as fasSliders } from '@awesome.me/kit-79798a8675/icons/classic/solid'
import { faFacebook, faPinterest, faYoutube, faInstagram  } from '@fortawesome/free-brands-svg-icons'
import { faEmaerket } from '@awesome.me/kit-79798a8675/icons/kit/custom'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
	faFacebook, faPinterest, faYoutube, faInstagram, faArrowLeft, faArrowDown, faArrowUp, faArrowRight, faArrowUpRightFromSquare, faTrash, faPen, faPlusCircle, faMinusCircle, faPlus, faMinus, faAngleRight, faChevronDown, faChevronLeft, faChevronRight, faSpinner, faTimes, faTimesCircle, faCircleXmark, faPlayCircle, faInfoCircle, faCircleNotch, faCheck, faCheckCircle, faMagnifyingGlass, faSearch, faClipboard, faBookOpen, faCalendar, faTruck, faPiggyBank, faTruckFast, faShop, faCartShopping, faBars, faXmark, faPlay, faLock,
	fasFaArrowLeft, fasArrowRight, fasFaArrowDown, fasFaChevronDown, fasFaCartShopping, fasFaInfoCircle, fasFaCircleXmark, fasFaShop, faPhone, faTag, fasTag, fasFaCheck, fasBars, faTags,
	faEmaerket, fasSliders
)

dom.watch()

export default FontAwesomeIcon
