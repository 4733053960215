import * as ko from 'knockout';


export default class Universe {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        // Add class to body, so we know that we are in Universe context
        if (valueAccessor().target == "#js-universe") {
            document.body.classList.add('universe');

            // Replace Bootstrap col 11 with 12, to match Universe design
            let productListItems: HTMLCollectionOf<Element> = document.getElementsByClassName('js-product-list-item')
            let productListItemsArray: Array<Element> = Array.from(productListItems);
            productListItemsArray.forEach(item => {
                item.classList.remove('col-11');
                item.classList.add('col-12');
            });
        }
    }
}
ko.bindingHandlers['universe'] = new Universe();
