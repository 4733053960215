import * as ko from 'knockout';
import $ from 'jquery';

export default class TopBanner {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var target = $(value.target);
        var endpoint = value.endpoint;

        $(element).click(function () {

            target.slideUp();

            $.ajax({
                type: "GET",
                url: endpoint + '?HideMessageBanner=true',
                dataType: "json",
                contentType: "application/json; charset=utf-8"
            });

        });
    }
}
ko.bindingHandlers['topBanner'] = new TopBanner();
