<script setup lang="ts">
import { getTranslation, setShop } from '@/ts/utilities.ts'
import { MarkerData } from '../interfaces/MapsViewInterface'
import RenderAction from '@/components/helpers/RenderAction.vue'

interface Props {
	dealer: MarkerData
}

const props = defineProps<Props>()

const dealer = props.dealer
</script>

<template>
	<div class="flex flex-col relative gap-4 justify-center bg-white hover:no-underline py-8 px-6 rounded-md hover:shadow">
		<address class="not-italic">
			<p class="text-lg">{{ dealer.ShopName }}</p>
			{{ dealer.Address }}<br>
			{{ dealer.PostalAndCity }}<br>
			{{ dealer.Phone }}<br>
			<a :href="`mailto:${dealer.Email}`" class="text-black underline">
				{{ dealer.Email }}
			</a>
		</address>
		<strong>
			{{ getTranslation('FindShopOpeningHoursHeader') }}
		</strong>
		<div v-html="dealer.OpeningHours"></div>
		<div class="flex mt-auto">
			<RenderAction
				action="button"
				theme="primary"
				@click="setShop(dealer)"
			>
				{{ getTranslation('FindShopCTA') }}
			</RenderAction>
		</div>
	</div>
</template>
