<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
	theme: {
		type: String,
		validator(value, props) {
			return ['success', 'warning', 'info', 'danger'].includes(<string>value)
		}
	},
	content: {
		type: Object,
	}
})

const notificationData = {
	bannerStyle: {
		backgroundColor: props.content?.bannerStyle.backgroundColor,
		textColor: props.content?.bannerStyle.textColor
	},
	bannerContent: {
		link: props.content?.bannerContent[0].link,
		text: props.content?.bannerContent[0].text
	}
}

const setBackgroundColor = () => {
	if (props.theme == 'success') return 'bg-success'
	if (props.theme == 'warning') return 'bg-warning'
	if (props.theme == 'info') return 'bg-info'
	if (props.theme == 'danger') return 'bg-danger'
	return 'bg-white'
}

const textColor = ref('')
textColor.value = notificationData?.bannerStyle.textColor
</script>

<template>
	<div class="p-4 w-full"
		:class="setBackgroundColor()"
		:style="{ backgroundColor: notificationData.bannerStyle.backgroundColor }">
		<div v-if="$slots.content"
			 class="flex flex-col md:flex-row items-center justify-center text-center md:text-left text-balance gap-2 w-full"
			 :style="{ color: textColor }">
			<slot name="content"></slot>
		</div>
		<div v-else>
			<div v-if="notificationData.bannerContent.link">
				<a :href="notificationData.bannerContent.link"
				   :style="{ color: textColor }"
				   v-html="notificationData.bannerContent.text"></a>
			</div>
			<div v-else
				 :style="{ color: textColor }">
				{{ notificationData.bannerContent.text }}
			</div>
		</div>
	</div>

</template>

<style scoped>

</style>
