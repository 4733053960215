import * as ko from 'knockout';
import $ from 'jquery';

export default class MenuToggler {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var MenuItemSelector = $(value.menuItemSelector);
        var SubMenuItemSelector = $(value.subMenuItemSelector);
        var MenuOpen = value.isMenuOpenObs;
        var ProductsArray = value.productsArray;
        var SearchError = value.searchError;

        var CloseSubDrops = () => {
            SubMenuItemSelector.removeClass("sub-open");
        }
        SubMenuItemSelector.click(function () {
            var element = $(this)
            if (!element.hasClass("sub-open")) {
                CloseSubDrops();
                element.addClass("sub-open");
            }
            else if ($('.js-mobile-navigation').is(':visible')) {
                element.removeClass('sub-open');
            }
        });

        var CloseDrops = () => {
            MenuItemSelector.removeClass("open");
        }
        MenuItemSelector.click(function () {
            var element = $(this);
            if (!element.hasClass("open")) {
                CloseDrops();
                element.addClass("open");
                MenuOpen(true);
                ProductsArray([]);
                SearchError(false);
            }
            else if ($('.js-mobile-navigation').is(':visible')) {
                element.removeClass('open');
            }
        });

        MenuOpen.subscribe(() => {
            if (!MenuOpen()) {
                CloseDrops();
            }
        });

    }
}
ko.bindingHandlers['menuToggler'] = new MenuToggler();
