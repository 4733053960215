<script setup lang="ts">
import Modal from '@/components/helpers/Modal.vue'
import {getSettings, getTranslation} from '../ts/utilities.ts'
import RenderAction from '@/components/helpers/RenderAction.vue'
import {onMounted, ref} from 'vue'

const settings = getSettings()

const props = defineProps({
	show: Boolean,
	shopName: String,
	text: String,
	isLocalShop: Boolean
})

const cookieLocalShop = ref('')
const showModal = ref(false)

const approveLocalShop = () =>{
	showModal.value = false
	const date = new Date()
	date.setTime(date.getTime() + (100000000000))
	const expires = `; expires=${date}`
	document.cookie = `LocalShop${props.shopName}=${props.shopName}${expires}; path=/`
}

const returnToWebsite = () =>{
	window.history.back()
}

onMounted(() =>{
	showModal.value = props.show
	cookieLocalShop.value = ('; '+document.cookie).split(`; LocalShop${props.shopName}=`).pop().split(';')[0]
	if(cookieLocalShop.value !== props.shopName){
		if (props.isLocalShop) {
			showModal.value = true
		}
	}
})


</script>

<template>
	<Modal :show="showModal" @close="showModal.value = false">
		<template #modal-title>{{ settings?.shopInfos[0]?.ShopName }}</template>
		<template #content>
			<div>
				{{getTranslation('V2:BeforeRedirectToLocalWebshopConfirmationText')}} {{ settings?.shopInfos[0]?.ShopName }}
			</div>
		</template>
		<template #footer>
			<div class="flex justify-content-end gap-2">
				<RenderAction
					action="button"
					theme="primary"
					v-on:click="returnToWebsite">
					{{ getTranslation("FindShopPopupCancel") }}
				</RenderAction>
				<RenderAction
					action="button"
					theme="primary"
					v-on:click="approveLocalShop">
					{{ getTranslation("FindShopPopupConfirm") }}
				</RenderAction>
			</div>
		</template>

	</Modal>
</template>
