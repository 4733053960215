<script setup lang="ts">
import {onBeforeMount, ref} from "vue";
import {getTranslation, getSettings} from "@/ts/utilities.ts";

const settings = getSettings()

const props = defineProps({
	type: {
		type: Number,
	},
	splashTextColor: {
		type: String,
	},
	splashBackgroundColor: {
		type: String,
	},
})

let splashTranslations = ref()

splashTranslations.value = {
	translationSplashProductOffer: getTranslation('ProductOffer'),
	translationSplashNewItem: getTranslation('ProductNew'),
	translationSplashLowPrice: getTranslation('ProductLowPrice'),
	translationSplashDailyPrice: getTranslation('ProductDailyPriceSplash'),
	translationSplashCombinationOffer: getTranslation('ProductCombinationOffer'),
	translationSplashJubileePrice: getTranslation('ProductJubileePrice')
}


// Set splash class
let splashInfo = ref({
	class: '',
	text: ''
})

const setBlackFridayTheme = () => {
	if (settings?.blackFridayTheme) {
		return 'bg-black text-white'
	} else {
		return 'bg-yellow text-black'
	}
}

const setSplash = () => {
	if (props.type == 1) {
		splashInfo.value = {
			class: setBlackFridayTheme(),
			text: splashTranslations.value.translationSplashProductOffer
		}
	} else if (props.type == 2) {
		splashInfo.value = {
			class: 'bg-primary text-white',
			text: splashTranslations.value.translationSplashNewItem
		}
	} else if (props.type == 3) {
		splashInfo.value = {
			class: 'bg-purple text-white',
			text: splashTranslations.value.translationSplashLowPrice
		}
	} else if (props.type == 4) {
		splashInfo.value = {
			class: 'bg-black text-yellow',
			text: splashTranslations.value.translationSplashDailyPrice
		}
	} else if (props.type == 5) {
		splashInfo.value = {
			class: 'bg-yellow text-black',
			text: splashTranslations.value.translationSplashCombinationOffer
		}
	} else if (props.type == 6) {
		splashInfo.value = {
			class: 'bg-beige-light text-gold',
			text: splashTranslations.value.translationSplashJubileePrice
		}
	}
}

onBeforeMount(() => {
	setSplash()
})

</script>

<template>
	<div
		class="flex items-center justify-center h-16 w-16 absolute top-4 right-4 p-4 rounded-full product-card-splash"
		:class="splashInfo.class"
		:style="props.splashBackgroundColor ? `background-color: ${props.splashBackgroundColor};` : ''"
  		>
	    <p class="m-0 uppercase text-xs font-bold leading-4 text-center"
			:style="props.splashTextColor ? `color: ${props.splashTextColor};` : ''"
			>
			{{ splashInfo.text }}
		</p>
  	</div>
</template>
