<script setup lang="ts">
import { getTranslation, getSettings } from '@/ts/utilities.ts'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const settings = getSettings()

const setShopLink = () => {
	if (settings?.localShopPage && settings?.shopname !== 'møblér') {
		return settings.localShopPage
	} else if (settings?.findDealerLink) {
		return settings.findDealerLink
	}
}
</script>

<template>
	<div class="flex items-center justify-end gap-x-4 md:gap-x-6 lg:gap-x-4 xl:gap-x-6 w-full">
		<a
			v-if="settings?.avisLink"
			:href="settings?.avisLink"
		   	class="flex items-center flex-col md:flex-row md:gap-2 no-underline text-black hover:text-black/75"
		>
			<font-awesome-icon :icon="['fal', 'book-open']" class="fa-2xl" />
			<small class="text-xs mt-1 md:text-sm md:mt-0 max-md:text-center">
				{{ getTranslation('HeaderNav.News') }}
			</small>
		</a>

		<a
			v-if="settings?.localShopPage || settings?.findDealerLink"
			:href="setShopLink()"
			class="flex items-center flex-col md:flex-row md:gap-2 no-underline text-black hover:text-black/75"
		>
			<font-awesome-icon :icon="['fal', 'shop']" class="fa-2xl" />
			<small class="text-xs mt-1 md:text-sm md:mt-0 max-md:text-center">
				{{ settings?.localShopPage && settings?.shopname !== 'møblér' ? getTranslation('MenuAboutUs.Text') : getTranslation('HeaderNav.FindShop')}}
			</small>
		</a>

		<a
			v-if="settings?.cartPage"
			:href="settings?.cartPage"
			class="flex items-center flex-col md:flex-row md:gap-2 no-underline text-black hover:text-black/75"
		>
			<div class="relative">
				<font-awesome-icon :icon="['fal', 'cart-shopping']" class="fa-2xl" />
				<small class="absolute -top-2 -right-2 text-xs flex items-center justify-center bg-primary text-white rounded-full w-4 h-4 p-3">
					{{ settings?.cartCount ? settings.cartCount : 0 }}
				</small>
			</div>
			<small class="text-xs mt-1 md:text-sm md:mt-0 max-md:text-center">
				{{ getTranslation('HeaderNav.Cart') }}
			</small>
		</a>
	</div>
</template>
