<script setup lang="ts">
import {onMounted, ref} from "vue";

const props = defineProps({
	emark: {
		type: Boolean,
		default: false
	},
	paymentMethodId: {
		type: String,
		default: ''
	},
	allPayments: {
		type: Boolean,
		default: false
	},
	cards: {
		type: Boolean,
		default: false
	},
	mobilepay: {
		type: Boolean,
		default: false
	},
	financing: {
		type: Boolean,
		default: false
	}
})

const cards = ref(false)
const mobilepay = ref(false)
const financing = ref(false)

const setPaymentMethodLogos = () => {
	if (props.paymentMethodId === 'PAY8') {
		cards.value = true
	} else if (props.paymentMethodId === 'PAY9') {
		mobilepay.value = true
	} else if (props.paymentMethodId === 'PAY2') {
		financing.value = true
	}
}

onMounted(() => {
	setPaymentMethodLogos()
})

</script>

<template>
	<ul class="list-none flex items-center gap-x-2">
		<li v-if="props.emark">
			<img width="200" height="50" src="/img/emaerke.png" alt="EMærke">
		</li>
		<li v-if="props.allPayments || props.cards || cards">
			<img width="41" height="25" src="/img/DK_Logo_konturstreg.png" alt="Dankort"/>
		</li>
		<li v-if="props.allPayments || props.cards || cards">
			<img width="34" height="22" src="/img/visa.png" alt="VISA"/>
		</li>
		<li v-if="props.allPayments || props.cards || cards">
			<img width="35" height="22" src="/img/visa-elektron.png" alt="VISA Elektron"/>
		</li>
		<li v-if="props.allPayments || props.cards || cards">
			<img width="33" height="22" src="/img/mc_vrt_pos.png" alt="American express"/>
		</li>
		<li v-if="props.allPayments || props.mobilepay || mobilepay">
			<img width="76" height="25" src="/img/DK_Logo_mobilepay.png" alt="Mobilepay"/>
		</li>
		<li v-if="props.allPayments || props.financing || financing">
			<img width="109" height="20" src="/img/santander.png" alt="Santander"/>
		</li>
	</ul>
</template>
