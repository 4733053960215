<script setup lang="ts">
import { getSettings, getTranslation } from "@/ts/utilities.ts"
import {onMounted, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import RenderAction from "@/components/helpers/RenderAction.vue";
import ShowModal from "@/components/ShowModal.vue";

const settings = getSettings()

const usps = ref([])

const getUsps = () => {
	usps.value = settings.pageTopUsps
}

onMounted(() => {
	getUsps()
})
</script>

<template>
	<div v-if="settings.enableTopBarHeader"
		class="container-fluid">
		<div class="bg-beige py-2 hidden lg:flex">
			<div class="container flex justify-between">
				<ul v-if="usps"
					class="flex gap-8 list-none">
					<li
						v-for="(usp, index) in usps"
						:key="index"
						class="flex items-center gap-x-2">
						<ShowModal
							v-if="usp.showContentInModal"
							:modal-title="usp.header"
							:modal-content="usp.contentText">
							<font-awesome-icon :icon="['fal', usp.icon]" />
							<small>{{ usp.header }}</small>
						</ShowModal>

						<RenderAction
							v-else-if="usp.link"
							theme="link"
							size="none"
							:link="usp.link">
							<font-awesome-icon
								v-if="usp.icon"
								:icon="['fal', usp.icon]"
							/>
							<small>{{ usp.header }}</small>
						</RenderAction>

						<template v-else>
							<font-awesome-icon
								v-if="usp.icon"
								:icon="['fal', usp.icon]"
							/>
							<small>{{ usp.header }}</small>
						</template>
					</li>
				</ul>
				<ul class="flex gap-4 list-none">
					<li v-if="settings?.contactUsPage">
						<RenderAction
							action="link"
							theme="link"
							size="none"
							:data="settings?.contactUsPage">
							<small>{{ getTranslation('Header.ContactUs') }}</small>
						</RenderAction>
					</li>
					<li class="flex items-center gap-x-2">
						<small>{{ getTranslation('E-Mærket') }}</small>
						<font-awesome-icon :icon="['fak', 'emaerket']" />
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
