<script setup lang="ts">

const props = defineProps({
	data: {
		type: String,
	},
	target: {
		type: String,
	},
	theme: {
		type: String,
		validator(value, props) {
			return ['primary', 'secondary', 'outline', 'faded', 'link'].includes(<string>value)
		}
	},
	size: {
		type: String,
		default: "normal",
		validator(value, props) {
			return ['sm', 'normal', 'lg'].includes(<string>value)
		}
	},
})

</script>

<template>
	<a
		:href="props.data"
		:target="props.target"
		rel="noopener"
		class="hover:no-underline focus:no-underline"
		>
		<slot></slot>
	</a>
</template>

<style scoped>

</style>
