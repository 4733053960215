<script setup>
import { onMounted, ref, watch } from 'vue'
import Modal from '@/components/helpers/Modal.vue'
import RenderAction from '@/components/helpers/RenderAction.vue';

const props = defineProps({
	fetchFromApi: {
		type: Boolean,
		default: false
	},
	endpoint: {
		type: String,
		default: ''
	},
	linkText: {
		type: String,
		default: 'Show modal'
	},
	modalTitle: {
		type: String,
		default: 'Modal Title'
	},
	modalContent: {
		type: String,
		default: 'Modal Test'
	},
	buttonOptions: {
		type: Object,
		default: () => ({
			action: 'button',
			theme: 'link',
			size: 'none'
		})
	}
})

let showModal = ref(false)
let title = ref('')
let content = ref('')

// Get content from the API endpoint that's set through the modalContent prop
async function getContent(endpoint) {
	await fetch(endpoint)
		.then((response) => response.json())
		.then((data) => {
			// Check that there are fields present, and that one of the objects has a SystemName of "Title", and a non-empty "Value".
			if (data.Item.Fields.length > 0 && data.Item.Fields.some((item) => item.SystemName === 'Title' && item.Value !== '')) {
				title.value = data.Item.Fields.find((item) => item.SystemName === 'Title').Value
			} else {
				title.value = data.Name
			}

			if (data.Item.Fields.length > 0 && data.Item.Fields.some((item) => item.SystemName === 'Desctiption' && item.Value !== '')) {
				content.value = data.Item.Fields.find((item) => item.SystemName === 'Desctiption').Value
			} else {
				content.value = data.Text
			}
		})
}

onMounted(() => {
	if (props.fetchFromApi && props.endpoint !== '') {
		getContent(props.endpoint)
	} else {
		title.value = props.modalTitle
		content.value = props.modalContent
	}
})

// If modal is opened, append the overflow-hidden class to the html element
watch(showModal, (value) => {
	if (value) {
		document.documentElement.classList.add('overflow-hidden', 'modal-open')
	} else {
		document.documentElement.classList.remove('overflow-hidden', 'modal-open')
	}
})
</script>

<template>
	<RenderAction
		:action="props.buttonOptions.action"
		:theme="props.buttonOptions.theme"
		:size="props.buttonOptions.size"
		@click.stop="showModal = true">
		<slot></slot>
	</RenderAction>

	<Modal :show="showModal" @close="showModal = false" :inline="true">
		<template #modal-title>
			{{ title }}
		</template>
		<template #content>
			<div class="prose-sm lg:prose lg:max-w-none" v-html="content"></div>
		</template>
	</Modal>
</template>
