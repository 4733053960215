<script setup lang="ts">
import {onMounted, ref} from 'vue'
import YouTubePlayer from 'youtube-player'
import { getTranslation } from '@/ts/utilities.ts'
import RenderAction from '@/components/helpers/RenderAction.vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

defineExpose({ stopVideo })

declare let Cookiebot: any;
let player: any;

interface Props {
  video: string
}

const props = defineProps<Props>()

const video = ref(null)
const videoPoster = ref<HTMLDivElement | null>(null)
const videoId = props.video

function startVideo(video: any, videoId: string) {
	console.log('video', video)
	console.log('videoId', videoId)
	player = YouTubePlayer(video, {
		videoId,
		playerVars: {
			autoplay: 1,
			controls: 1,
			rel: 0,
			enablejsapi: 1,
			origin: window.location.origin,
			mute: 1
		}
	})
	player.playVideo()
}

function stopVideo() {
	if(video.value && player){
		player
			.pauseVideo()
			.then(() => {
				console.log('video paused')
			})
	}
}

const getPosters = new Promise((resolve) => {
	let source: string = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
	resolve(source)
})

// Poster handling
function setPoster(source: string) {
  let poster = new Image();
  poster.src = source;
  poster.width = 1280;
  poster.height = 720;
  poster.classList.add('w-full', 'h-full', 'object-cover', 'aspect-[16/9]');
  //poster.alt = title ? title : 'Video poster';

  return new Promise((resolve) => {
	  resolve(poster);
  });
}

const renewCookies = () => {
  Cookiebot.renew()
}

onMounted(() => {
	getPosters.then((source) => {
		setPoster(source).then((poster) => {
			videoPoster.value?.appendChild(poster)
		})
	})
})
</script>

<template>
  <div class="relative">
    <div class="video-wrapper w-full h-full aspect-[16/9]" ref="video">
      <div class="video-poster relative" ref="videoPoster">
		  <div class="absolute inset-0 flex items-center justify-center bg-black/25">
			  <div class="cookieconsent-optin-marketing">
				  <RenderAction
					  action="button"
					  theme="white"
					  size="sm"
					  @click="startVideo(video, props.video)"
				  >
					  <font-awesome-icon :icon="['fal', 'play']"/>
					  {{ getTranslation('Product.WatchVideo') }}
				  </RenderAction>
			  </div>
			  <template class="cookieconsent-optout-marketing">
				  <div class="absolute inset-0 p-4 w-full h-full object-fill bg-white/75 flex flex-col items-center justify-center text-center text-pretty">
					  {{ getTranslation('BlockedContentAllowCookiesText') }}
					  <RenderAction
						  action="button"
						  theme="white"
						  size="sm"
						  @click="renewCookies"
					  >
						  {{ getTranslation('ChangeCookieSettingsButtonText') }}
					  </RenderAction>
				  </div>
			  </template>
		  </div>
	  </div>
    </div>
  </div>
</template>
