import * as ko from 'knockout';
import $ from 'jquery';

export default class ScrollToElement {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var targetElement = $(value.target);
        var clickElement = $(element);

        clickElement.click(function () {
            var targetDistanceFromTop = targetElement.offset().top - 50;
            $("html, body").animate({ scrollTop: targetDistanceFromTop }, "ease");
        });

    }
}
ko.bindingHandlers['scrollToElement'] = new ScrollToElement();
