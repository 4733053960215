<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import { getTranslation } from "@/ts/utilities.ts";
import RenderAction from '@/components/helpers/RenderAction.vue'
import { PriceAndCampaign } from '@/interfaces/PriceAndCampaignInterface.ts'


interface Props {
	link: string,
	hasVariants: boolean,
	priceData: PriceAndCampaign,
	theme: string,
	direction?: string,
	size?: string
}

const props = withDefaults(defineProps<Props>(), {
	theme: 'card',
	direction: 'vertical'
})

const priceData = ref(null)
const hasCampaign = ref(false)

const setCampaignDateRange = () => {
	if (!hasCampaign) return

	const campaignStartDate = new Date(priceData.value?.campaign?.startDate)
	const campaignEndDate = new Date(priceData.value?.campaign?.endDate)

	const dateFormat: object = {
		day: 'numeric',
		month: 'long',
	}

	const startDate = campaignStartDate.toLocaleString('da-DK', dateFormat)
	const endDate = campaignEndDate.toLocaleString('da-DK', dateFormat)

	const prependDateText = getTranslation('Campaign.BeforeDate.Text')
	const splitDateRange = getTranslation('Campaign.BeforeDate.Text2')

	return `${prependDateText} ${startDate} ${splitDateRange} ${endDate}`
}

onMounted(() => {
	priceData.value = props.priceData;

	hasCampaign.value = priceData.value?.hasActiveCampaign && priceData.value?.campaign?.startDate && priceData.value?.campaign?.endDate
	setCampaignDateRange()
})

watch(() => props.priceData, (val) =>{
	priceData.value = val;
}, {
	deep:true
})

</script>

<template>
	<div class="flex flex-col" v-if="priceData">
		<div v-if="hasCampaign"
		 class="flex items-center gap-2 text-xs text-grey-dark"
		:class="props.theme == 'page' || props.theme == 'line' ? 'order-2' : 'mb-2'">
			<span class="relative flex h-3 w-3">
				<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow opacity-50"></span>
				<span class="inline-flex rounded-full h-3 w-3 bg-yellow"></span>
			</span>
			{{ setCampaignDateRange() }}
		</div>
		<div class="py-2 w-full"
			:class="props.theme == 'card' ? 'border-t border-grey border-b' : ''">
			<div class="flex items-center"
				:class="props.theme == 'line' ? 'justify-end' : 'justify-between'">
				<div :class="props.direction == 'vertical' ?
					'flex flex-col' :
					'grid grid-cols-2 items-center gap-x-3'
					">
					<span v-if="priceData?.priceSavings || priceData?.totalSaving"
						  class="bg-yellow inline uppercase w-fit px-1 text-sm font-bold mb-1"
						  :class="props.direction == 'horizontal' ? 'col-start-2 row-start-1' : ''">
						{{ getTranslation('PriceSaving') }}
						<template v-if="priceData?.priceSavings">{{ priceData.priceSavings.formatted }}</template>
						<template v-else-if="priceData?.totalSaving">{{ priceData.totalSaving.formatted }}</template>
					</span>
					<span class="font-bold"
						:class="props.theme == 'page' && props.size != 'sm' ? 'text-5xl' : props.theme == 'line' ? 'text-2xl' : 'text-3xl'">
						<small class="mr-1" v-if="props?.hasVariants">{{ getTranslation('ProductPrice.From') }}</small>
						<template v-if="priceData?.price">
							{{ priceData?.price?.formatted }}
						</template>
						<template v-else-if="priceData?.totalPrice">
							{{ priceData?.totalPrice?.formatted }}
						</template>
					</span>
					<span class="text-sm line-through text-grey-dark"
						:class="props.direction == 'horizontal' ? 'col-span-2' : ''">
						<template v-if="priceData?.productSplash?.typeName == 'combo'">
							{{ getTranslation('PriceBeforeCombo') }}
						</template>
						<template v-else-if="priceData?.priceSavings || priceData.totalSaving">
							{{ getTranslation('PriceBefore') }}
						</template>
						<template v-if="priceData?.priceNormal">&nbsp;{{ priceData?.priceNormal?.formatted }}</template>
						<template v-else-if="priceData?.totalNormalPrice">&nbsp;{{ priceData?.totalNormalPrice?.formatted }}</template>
					</span>
				</div>
				<!-- TODO: Tilføj translation -->
				<RenderAction
					v-if="props.theme == 'card'"
					:data="props.link"
					action="link"
					theme="faded"
					size="sm"
				>
					Vis
				</RenderAction>
			</div>
		</div>
	</div>
</template>
